.classes-main {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5372f0;
}
::selection {
  color: #fff;
  background: #5372f0;
}
.wrapper {
  width: 380px;
  padding: 40px 30px 50px 30px;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
}
.wrapper header {
  font-size: 35px;
  font-weight: 600;
}
.wrapper form {
  margin: 40px 0;
}
form .field {
  width: 100%;
  margin-bottom: 20px;
}
form .field.shake {
  animation: shake 0.3s ease-in-out;
}
@keyframes shake {
  0%,
  100% {
    margin-left: 0px;
  }
  20%,
  80% {
    margin-left: -12px;
  }
  40%,
  60% {
    margin-left: 12px;
  }
}
form .field .input-area {
  height: 50px;
  width: 100%;
  position: relative;
}
form input {
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0 45px;
  font-size: 18px;
  background: none;
  caret-color: #5372f0;
  border-radius: 5px;
  border: 1px solid #bfbfbf;
  border-bottom-width: 2px;
  transition: all 0.2s ease;
}
form .field input:focus,
form .field.valid input {
  border-color: #5372f0;
}
form .field.shake input,
form .field.error input {
  border-color: #dc3545;
}
.field .input-area i {
  position: absolute;
  top: 50%;
  font-size: 18px;
  pointer-events: none;
  transform: translateY(-50%);
}
.input-area .icon {
  left: 15px;
  color: #bfbfbf;
  transition: color 0.2s ease;
}
.input-area .error-icon {
  right: 15px;
  color: #dc3545;
}
form input:focus ~ .icon,
form .field.valid .icon {
  color: #5372f0;
}
form .field.shake input:focus ~ .icon,
form .field.error input:focus ~ .icon {
  color: #bfbfbf;
}
form input::placeholder {
  color: #bfbfbf;
  font-size: 17px;
}
form .field .error-txt {
  color: #dc3545;
  text-align: left;
  margin-top: 5px;
}
form .field .error {
  display: none;
}
form .field.shake .error,
form .field.error .error {
  display: block;
}
form .pass-txt {
  text-align: left;
  margin-top: -10px;
}
.wrapper a {
  color: #5372f0;
  text-decoration: none;
}
.wrapper a:hover {
  text-decoration: underline;
}
form input[type="submit"] {
  height: 50px;
  margin-top: 30px;
  color: #fff;
  padding: 0;
  border: none;
  background: #5372f0;
  cursor: pointer;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
form input[type="submit"]:hover {
  background: #2c52ed;
}
