.result-input-elect-nursery1 {
  width: 380px;
  text-align: left;
  border-color: #5372f0;
  border: 1px solid #5372f0;
}
.container .titler {
  font-size: large;
  font-weight: 500;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}
.container .titler::before {
  content: "";
  position: absolute;
  align-items: center;
  right: 0;
  left: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  height: 3px;
  width: 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
@media screen and (max-width: 1200px) {
  .result-input-elect-nursery1 {
    width: 250px;
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .result-input-elect-nursery1 {
    width: 350px;
    text-align: left;
  }
}
@media screen and (max-width: 480px) {
  .result-input-elect-nursery1 {
    width: 220px;
    text-align: left;
  }
  .text-texfield-input {
    border-width: 150px;
  }
}
