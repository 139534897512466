.app-bar {
  background-color: white;
  color: #000000;
  margin-top: 48px;
  margin-bottom: 0px;
  /* margin-top: 120.5px; */
  /* width: min-content; */
  /* position: static;
  height: 10vh; */
}
.menu-icon {
  display: flex;
  position: relative;
  left: 67480px;
  right: 0px;
}

.header-icon-btn {
  display: none;
}
.logo-div-img {
  width: 150px;
  height: 60px;
}
.logo-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1400px) {
  .menu-icon {
    display: none;
  }
}

@media (max-width: 968px) {
  .menu-icon {
    display: flex;
    position: relative;
    left: 380px;
    right: 0px;
  }
}
@media (max-width: 768px) {
  .app-bar {
    margin-top: 0px;
  }
  .logo-div-img {
    width: 150px;
    height: 60px;
    margin-left: -50px;
    margin-right: 0px;
  }
  .menu-icon {
    position: relative;
    left: 400px;
    right: 0px;
  }
}
@media (max-width: 480px) {
  .app-bar {
    margin-top: 0px;
  }
  .logo-div-img {
    width: 150px;
    height: 60px;
    margin-left: -50px;
    margin-right: 0px;
  }
  .menu-icon {
    position: relative;
    left: 300px;
    right: 0px;
  }
}
